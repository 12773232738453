* {
  box-sizing: border-box;
}


@font-face {
	font-family: 'Pretendard-Bold';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: 
    url('./assets/font/Pretendard-Bold.otf') format('otf'),
    url('./assets/font/Pretendard-Bold.ttf') format('truetype'),
    url('./assets/font/Pretendard-Bold.woff') format('woff'),
    url('./assets/font/Pretendard-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Pretendard-Black';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: 
  url('./assets/font/Pretendard-Black.otf') format('otf'),
  url('./assets/font/Pretendard-Black.ttf') format('truetype'),
  url('./assets/font/Pretendard-Black.woff') format('woff'),
  url('./assets/font/Pretendard-Black.woff2') format('woff2');
}

@font-face {
	font-family: 'Pretendard-Regular';
	font-weight: 400;
	font-display: swap;
	src: 
    url('./assets/font/Pretendard-Regular.otf') format('otf'),
    url('./assets/font/Pretendard-Regular.ttf') format('truetype'),
    url('./assets/font/Pretendard-Regular.woff') format('woff'),
    url('./assets/font/Pretendard-Regular.woff2') format('woff2');
}

body {
  margin: 0;
  font-size: 16px;
  font-family: "Pretendard-Regular";
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  height: 100%;


}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
ol,
ul,
li {
  list-style: none;
  padding: 0;
}
input,
button {
  outline: none;
  background-color: transparent;
  font-family: "SourceSansProRegular";
  color: #000000;
}
textarea {
  outline: none;
  font-family: "SourceSansProRegular";
}
